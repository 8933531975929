import React, { useRef, useState, useContext } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const Contacts = () => {
  const { language } = useContext(LanguageContext);
  const [successMessage, setSuccesMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const serviceId = "service_c7r1ay9";
  const templateId = "template_uaqpts9";
  const publicKey = "D1YbY6nAWqyS7d29L";

  const form = useRef();

  const onSubmit = (data, r) => {
    sendEmail(
      serviceId,
      templateId,
      {
        name: data.name,
        phone: data.phone,
        email: data.email,
        subject: data.subject,
        description: data.description,
      },
      publicKey
    );
    r.target.reset();
  };

  const sendEmail = (serviceId, templateId, variables, publicKey) => {
    emailjs
      .send(serviceId, templateId, variables, publicKey)
      .then(() => {
        setSuccesMessage(texts.contacts.successMessage[language]);
      })
      .catch((err) => console.error(`Something went wrong: ${err}`));
  };

  return (
    <div id="contacts" className="contacts">
      <div className="text-center">
        <h1>{texts.contacts.heading[language]}</h1>
        <p>{texts.contacts.description[language]}</p>
        <span className="success-message">{successMessage}</span>
      </div>
      <div className="container">
        <form ref={form} onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              {/* NAME INPUT */}
              <div className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder={texts.contacts.form.namePlaceholder[language]}
                  name="name"
                  {...register("name", {
                    required: true,
                    maxLength: 20,
                  })}
                />
                {errors.name && (
                  <span className="error-message">
                    {texts.contacts.form.errors.name[language]}
                  </span>
                )}
                <div className="line"></div>
              </div>
              {/* PHONE INPUT */}
              <div className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder={texts.contacts.form.phonePlaceholder[language]}
                  name="phone"
                  {...register("phone", {
                    required: false,
                  })}
                />
                {errors.phone && (
                  <span className="error-message">
                    {texts.contacts.form.errors.phone[language]}
                  </span>
                )}
                <div className="line"></div>
              </div>
              {/* EMAIL INPUT */}
              <div className="text-center">
                <input
                  type="email"
                  className="form-control"
                  placeholder={texts.contacts.form.emailPlaceholder[language]}
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                />
                {errors.email && (
                  <span className="error-message">
                    {texts.contacts.form.errors.email[language]}
                  </span>
                )}
                <div className="line"></div>
              </div>
              {/* SUBJECT INPUT */}
              <div className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder={texts.contacts.form.subjectPlaceholder[language]}
                  name="subject"
                  {...register("subject", {
                    required: true,
                  })}
                />
                {errors.subject && (
                  <span className="error-message">
                    {texts.contacts.form.errors.subject[language]}
                  </span>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              {/* DESCRIPTION */}
              <div className="text-center">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder={
                    texts.contacts.form.descriptionPlaceholder[language]
                  }
                  name="description"
                  {...register("description", {
                    required: true,
                  })}
                />
                {errors.description && (
                  <span className="error-message">
                    {texts.contacts.form.errors.description[language]}
                  </span>
                )}
                <div className="line"></div>
              </div>
              <button className="btn-main-offer contact-btn" type="submit">
                {texts.contacts.form.button[language]}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contacts;
