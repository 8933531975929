import React, { useContext } from "react";
import faureciaIcon from "../icons/faurecia.svg";
import plasticOmniumIcon from "../icons/plastic-omnium.svg";
import prolim from "../icons/prolim.svg";
import gojoIcon from "../icons/gojo.svg";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const Skills = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div id="skills" className="skills">
      <h1 className="py-5">{texts.companies.heading[language]}</h1>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img
                    className="icon-img"
                    src={faureciaIcon}
                    alt="Faurecia Icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img className="icon-img" src={prolim} alt="Prolim Icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img
                    className="icon-img"
                    src={plasticOmniumIcon}
                    alt="Plastic Omnium Icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="box">
              <div className="square">
                <div className="photo-wrap mb-5">
                  <img className="icon-img" src={gojoIcon} alt="Gojo Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
