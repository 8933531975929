import React, { useContext } from "react";
import profileEliane from "../profile-eliane.jpg";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const AboutMe = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div id="about" className="container py-5">
      <div className="row">
        <div className="col-lg-6 col-xm-12">
          <div className="photo-wrap mb-5">
            <img className="profile-img" src={profileEliane} alt="me" />
          </div>
        </div>
        <div className="col-lg-6 col-xm-12">
          <h1 className="about-heading">{texts.aboutMe.heading[language]}</h1>
          <p>{texts.aboutMe.description[language]}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
