import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/Navbar";
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import Services from "./components/Services";
import Skills from "./components/Skills";
import Footer from "./components/Footer";
import Contacts from "./components/Contacts";
import Testimonials from "./components/Testimonials";
import Intro from "./components/Intro";
import BuyButton from "./components/BuyButton";
import { LanguageProvider } from "./context/LanguageContext";

function App() {
  return (
    <>
      <LanguageProvider>
        <NavBar />
        <Header />
        <Intro />
        <AboutMe />
        <Services />
        <Testimonials />
        <Skills />
        <BuyButton />
        <Contacts />
        <Footer />
      </LanguageProvider>
    </>
  );
}

export default App;
