import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const Services = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div id="services" className="services">
      <h1 className="py-5">{texts.servicesSection.title[language]}</h1>
      <div className="container">
        <div className="row">
          {texts.servicesSection.services.map((service, index) => (
            <div key={index} className="col-md-3 col-sm-6">
              <div className="box">
                <div className="circle">
                  <FontAwesomeIcon
                    className="icon"
                    icon={service.icon}
                    size="2x"
                  />
                </div>
                <h3>{service.title[language]}</h3>
                <p>{service.description[language]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
