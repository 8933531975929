import React, { useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const TestimonialsCarousel = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={3000}
    >
      {texts.testimonialsCarousel.testimonials.map((testimonial, index) => (
        <div key={index}>
          <img src={testimonial.img} alt={testimonial.name} />
          <div className="myCarousel">
            <h3>{testimonial.name}</h3>
            <h6 className="role">{testimonial.role[language]}</h6>
            <p>{testimonial.text[language]}</p>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default TestimonialsCarousel;
