import {
  faChalkboardTeacher,
  faFlagUsa,
  faRocket,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import avatar1 from "../src/images/avatars/testimonial1.png";
import avatar2 from "../src/images/avatars/testimonial2.png";
import avatar3 from "../src/images/avatars/avatar-5.png";

export const texts = {
  home: {
    pt: "Início",
    en: "Home",
  },
  about: {
    pt: "Sobre mim",
    en: "About me",
  },
  services: {
    pt: "Serviços",
    en: "Services",
  },
  clients: {
    pt: "Clientes satisfeitos",
    en: "Satisfied clients",
  },
  companies2: {
    pt: "Clientes",
    en: "Clients",
  },
  contact: {
    pt: "Contato",
    en: "Contact",
  },
  header: {
    title: {
      pt: "Lançamento de curso em breve",
      en: "Course launching soon",
    },
    button: {
      pt: "Seja um Aluno 🚀",
      en: "Be a Student 🚀",
    },
    typedStrings: {
      pt: ["Inglês para Negócios", "Português para Estrangeiros"],
      en: ["Business English", "Portuguese for Foreigners"],
    },
  },
  course: {
    title: {
      pt: "Sobre o Curso",
      en: "About the Course",
    },
    info: {
      pt:
        "Nossas aulas são especificamente projetadas para o mundo dos negócios, perfeitas para quem busca se destacar no ambiente corporativo. O curso abrange vocabulário relevante para o mercado de trabalho e cenários empresariais reais. As aulas são comunicativas, dinâmicas e personalizadas, ajustadas para atender às expectativas e necessidades de cada aluno. Acreditamos em um aprendizado prático e envolvente, que facilita a aquisição de habilidades essenciais para o sucesso profissional.",
      en:
        "Our classes are specifically designed for the business world, perfect for those aiming to excel in the corporate environment. The course covers relevant vocabulary for the job market and real-life business scenarios. The lessons are communicative, dynamic, and personalized, tailored to meet each student's expectations and needs. We believe in practical and engaging learning, which facilitates the acquisition of essential skills for professional success.",
    },
  },
  aboutMe: {
    heading: {
      pt: "Sobre mim",
      en: "About me",
    },
    description: {
      pt: `Eu sou Eliane Valente, uma profissional experiente com mais de 35
          anos de atuação no ensino de inglês para negócios. Percebi a
          necessidade de oferecer aulas individuais e focadas para ajudar as
          pessoas a desenvolverem habilidades linguísticas específicas para o
          mundo empresarial, o que despertou minha paixão por ensinar e
          orientar nessa área. Além de minha experiência em educação para
          negócios, tenho paixão por leitura e me mantenho atualizada sobre as
          últimas notícias e tendências do mercado. Identificar oportunidades
          e tendências emergentes no mundo dos negócios é uma habilidade que
          trago para meu trabalho de ensino e orientação. Acredito que minha
          vasta experiência no ensino de inglês para negócios, aliada à minha
          paixão pelo ensino e aprendizagem, me tornam uma grande aliada para
          quem busca desenvolver habilidades linguísticas específicas e obter
          sucesso no mundo dos negócios internacionais. Meu comprometimento em
          ajudar as pessoas a terem sucesso me tornou uma mentora e educadora
          respeitada e confiável na comunidade de negócios.`,
      en: `I am Eliane Valente, an experienced professional with over 35
          years of experience in teaching business English. I recognized the
          need to offer individual and focused classes to help people develop
          specific language skills for the business world, which sparked my
          passion for teaching and mentoring in this area. In addition to my
          experience in business education, I have a passion for reading and
          stay updated on the latest market news and trends. Identifying
          opportunities and emerging trends in the business world is a skill I
          bring to my teaching and mentoring work. I believe that my vast
          experience in teaching business English, combined with my passion for
          teaching and learning, makes me a great ally for those looking to
          develop specific language skills and achieve success in the
          international business world. My commitment to helping people succeed
          has made me a respected and trusted mentor and educator in the
          business community.`,
    },
  },
  servicesSection: {
    title: {
      pt: "Assessoria Internacional",
      en: "International Consulting",
    },
    services: [
      {
        icon: faChalkboardTeacher,
        title: {
          pt: "Entrevistas de Emprego",
          en: "Job Interviews",
        },
        description: {
          pt:
            "Treinamento para entrevistas de emprego em inglês para empresas estrangeiras.",
          en: "Training for job interviews in English for foreign companies.",
        },
      },
      {
        icon: faFlagUsa,
        title: {
          pt: "Inglês para Negócios",
          en: "Business English",
        },
        description: {
          pt:
            "Cursos especializados para executivos, profissionais de diversas áreas e estudantes que necessitam do inglês para capacitação profissional.",
          en:
            "Specialized courses for executives, professionals from various fields, and students needing English for professional development.",
        },
      },
      {
        icon: faRocket,
        title: {
          pt: "Português para Estrangeiros",
          en: "Portuguese for Foreigners",
        },
        description: {
          pt:
            "Expatriados no Brasil que necessitam de aulas para si e para suas famílias, com aculturamento.            ",
          en:
            "Expatriates in Brazil who need lessons for themselves and their families, with acculturation.",
        },
      },
      {
        icon: faEarthAmericas,
        title: {
          pt: "Relocação",
          en: "Relocation",
        },
        description: {
          pt:
            "Auxílio com documentos, moradia, escolas, telefonia, transportes, etc.",
          en:
            "Assistance with documents, housing, schools, telephony, transportation, etc.",
        },
      },
    ],
  },
  testimonials: {
    heading: {
      pt: "Meus clientes satisfeitos",
      en: "My happy clients",
    },
  },
  testimonialsCarousel: {
    testimonials: [
      {
        img: avatar1,
        name: "Fernanda Mello",
        role: {
          pt: "Analista do departamento juridico da Confab-Tenaris",
          en: "Analyst at the Legal Department of Confab-Tenaris",
        },
        text: {
          pt:
            "As aulas com a equipe da Eliane fornecem segurança para atuarmos no Mercado Internacional. Ela utiliza situações reais para analisar diferentes perspectivas e discutir soluções e condutas. O conhecimento adquirido nas aulas nos ajuda a reconhecer expressões e nomes em inglês durante o trabalho, devido aos estudos prévios.",
          en:
            "The classes with Eliane's team provide confidence for us to operate in the international market. She uses real-life situations to analyze different perspectives and discuss solutions and conduct. The knowledge acquired in the classes helps us recognize expressions and terms in English during work, thanks to the prior studies.",
        },
      },
      {
        img: avatar2,
        name: "Ramirez Lopes",
        role: {
          pt:
            "Coordenador de politicas públicas para a juventude da Prefeitura Municipal de SP",
          en: "Youth Public Policy Coordinator at the São Paulo City Hall",
        },
        text: {
          pt:
            "Comecei as aulas com a Eliane dois meses antes de uma viagem internacional a trabalho, meu nível de inglês era o mais básico possível. Com suas aulas consegui fazer toda a viagem com muita tranquilidade e confiança para poder conversar em inglês. Um dos aprendizados mais importantes foi aprender a pensar em inglês.",
          en:
            "I started classes with Eliane two months before an international business trip, and my English level was as basic as it could be. With her classes, I managed to handle the entire trip with great ease and confidence in speaking English. One of the most important lessons was learning to think in English.",
        },
      },
      {
        img: avatar3,
        name: "Adriana Bittencourt",
        role: {
          pt: "Contadora Chefe Local na Alstom",
          en: "Local Chief Accountant at Alstom",
        },
        text: {
          pt:
            "Professora ótima e altamente qualificada, respeita o ritmo de cada aluno. As aulas são personalizadas e dinâmicas, utiliza de vários métodos além do livro, com foco nas necessidades de cada um. Prepara o aluno pra situações como participação em reuniões, revisão de relatórios e viagens.",
          en:
            "An excellent and highly qualified teacher who respects each student's pace. The classes are personalized and dynamic, using various methods beyond the textbook, focusing on individual needs. She prepares students for situations such as participating in meetings, reviewing reports, and traveling.",
        },
      },
    ],
  },
  companies: {
    heading: {
      pt: "Empresas já atendidas",
      en: "Companies already served",
    },
  },
  buyButton: {
    text: {
      pt: "🎉 Eu quero ser um aluno! 🎉",
      en: "🎉 I want to be a student! 🎉",
    },
  },
  contacts: {
    heading: {
      pt: "contate-me",
      en: "contact me",
    },
    description: {
      pt:
        "Por favor, preencha o formulário e entrarei em contato o mais breve possível.",
      en: "Please fill out the form and I'll contact you as soon as possible.",
    },
    successMessage: {
      pt: "Formulário enviado com sucesso",
      en: "Form sent successfully",
    },
    form: {
      namePlaceholder: {
        pt: "Nome",
        en: "Name",
      },
      phonePlaceholder: {
        pt: "Número de Telefone",
        en: "Phone Number",
      },
      emailPlaceholder: {
        pt: "Email",
        en: "Email",
      },
      subjectPlaceholder: {
        pt: "Assunto",
        en: "Subject",
      },
      descriptionPlaceholder: {
        pt: "Descrição",
        en: "Description",
      },
      errors: {
        name: {
          pt: "Por favor, insira seu nome",
          en: "Please enter your name",
        },
        phone: {
          pt: "Por favor, insira seu número de telefone",
          en: "Please add your phone number",
        },
        email: {
          pt: "Por favor, insira um email válido",
          en: "Please add a valid email",
        },
        subject: {
          pt: "Por favor, insira um assunto para o seu contato",
          en: "Please add a subject about your contact",
        },
        description: {
          pt: "Por favor, insira uma descrição sobre o seu contato",
          en: "Please enter a description about your contact",
        },
      },
      button: {
        pt: "contate-me",
        en: "contact me",
      },
    },
  },
  footer: {
    address: {
      pt: "Taubaté, São Paulo",
      en: "Taubaté, São Paulo",
    },
    phone: {
      pt: "+55(12)98142-3558",
      en: "+55(12)98142-3558",
    },
    email: {
      pt: "contato@elianevalente.com",
      en: "contato@elianevalente.com",
    },
    navigation: {
      home: {
        pt: "Início",
        en: "Home",
      },
      about: {
        pt: "Sobre mim",
        en: "About me",
      },
      services: {
        pt: "Serviços",
        en: "Services",
      },
      testimonials: {
        pt: "Clientes satisfeitos",
        en: "Happy Clients",
      },
      companies: {
        pt: "Empresas atendidas",
        en: "Companies Served",
      },
      contacts: {
        pt: "Contato",
        en: "Contact",
      },
    },
    copyright: {
      pt: "Todos os direitos reservados",
      en: "All Rights Reserved",
    },
  },
};

export default texts;
