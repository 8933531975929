import React, { useContext } from "react";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const Footer = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">
              <p>{texts.footer.address[language]}</p>
            </div>
            <div className="d-flex">
              <a href="tel:+55(12)98142-3558">{texts.footer.phone[language]}</a>
            </div>
            <div className="d-flex">
              <p>{texts.footer.email[language]}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div className="row">
              <div className="col">
                <Link
                  smooth={true}
                  to="home"
                  offset={-110}
                  className="footer-nav"
                >
                  {texts.footer.navigation.home[language]}
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="about"
                  offset={-110}
                  className="footer-nav"
                >
                  {texts.footer.navigation.about[language]}
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="services"
                  offset={-110}
                  className="footer-nav"
                >
                  {texts.footer.navigation.services[language]}
                </Link>
              </div>
              <div className="col">
                <Link
                  smooth={true}
                  to="testimonials"
                  offset={-110}
                  className="footer-nav"
                >
                  {texts.footer.navigation.testimonials[language]}
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="skills"
                  offset={-110}
                  className="footer-nav"
                >
                  {texts.footer.navigation.companies[language]}
                </Link>
                <br />
                <Link
                  smooth={true}
                  to="contacts"
                  offset={-110}
                  className="footer-nav"
                >
                  {texts.footer.navigation.contacts[language]}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            <div className="d-flex justify-content-center">
              <a
                href="https://www.linkedin.com/in/elianevalente/"
                className="Linkedin social"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" />
              </a>
              <a
                href="https://www.instagram.com/elianevalenteconsultoria/"
                className="Linkedin social"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="3x" />
              </a>
            </div>
            <p className="pt-3 text-center">
              Copyright&copy;{new Date().getFullYear()}&nbsp;Eliane Valente |{" "}
              {texts.footer.copyright[language]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
