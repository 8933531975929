import React, { useContext } from "react";
import confetti from "canvas-confetti";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

function handleClick() {
  confetti({
    particleCount: 150,
    spread: 70,
    origin: { x: 0.5, y: 1 },
  });

  window.open("https://example.com", "_blank");
}

const BuyButton = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="text-center">
      <button type="button" className="btn-buy-me" onClick={handleClick}>
        {texts.buyButton.text[language]}
      </button>
    </div>
  );
};

export default BuyButton;
