import React, { useContext } from "react";
import TestimonialsCarousel from "./TestimonialsCarousel";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const Testimonials = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div id="testimonials" className="testimonials">
      <h1>{texts.testimonials.heading[language]}</h1>
      <div className="container-testimonials">
        <div className="testimonials-content">
          <TestimonialsCarousel />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
