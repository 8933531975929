import React, { useContext } from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";
import texts from "../texts";
import { LanguageContext } from "../context/LanguageContext";

const Intro = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div id="intro" className="intro">
      <h1 className="py-5">{texts.course.title[language]}</h1>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="box">
              <p>{texts.course.info[language]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
