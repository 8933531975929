import React, { useContext } from "react";
import logo from "../logo-eliane.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Switch from "react-switch";
import brFlag from "../images/BR-Flag.png";
import usFlag from "../images/USA-Flag.jpg";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const NavBar = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img className="logo" src={logo} alt="logo"></img>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon icon={faBars} style={{ color: "#fff" }} />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link
                smooth={true}
                to="home"
                offset={-110}
                className="nav-link"
                href="#"
              >
                {texts.home[language]}{" "}
                <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="about"
                offset={-110}
                className="nav-link"
                href="#"
              >
                {texts.about[language]}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="services"
                offset={-110}
                className="nav-link"
                href="#"
              >
                {texts.services[language]}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="testimonials"
                offset={-110}
                className="nav-link"
                href="#"
              >
                {texts.clients[language]}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="companies"
                offset={-110}
                className="nav-link"
                href="#"
              >
                {texts.companies2[language]}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                smooth={true}
                to="contacts"
                offset={-110}
                className="nav-link"
                href="#"
              >
                {texts.contact[language]}
              </Link>
            </li>
            <a
              href="https://www.instagram.com/elianevalenteconsultoria/"
              className="Linkedin social"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a
              href="https://www.linkedin.com/in/elianevalente/"
              className="Linkedin social"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>

            <li className="nav-item">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 25,
                }}
              >
                <img
                  src={brFlag}
                  alt="Brazil Flag"
                  width="22"
                  style={{ marginRight: 8 }}
                />
                <Switch
                  onChange={toggleLanguage}
                  checked={language === "en"}
                  onColor="#6c757d"
                  offColor="#6c757d"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                <img
                  src={usFlag}
                  alt="USA Flag"
                  width="22"
                  style={{ marginLeft: 8 }}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
