import React, { useContext } from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";
import { LanguageContext } from "../context/LanguageContext";
import texts from "../texts";

const Header = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div id="home" className="header-wraper">
      <div className="main-info">
        <h1>{texts.header.title[language]}</h1>
        <Typed
          className="typed-text"
          strings={texts.header.typedStrings[language]}
          fadeOut={false}
          typeSpeed={40}
          backSpeed={60}
          smartBackspace={true}
          loop
        />
        <Link
          href="#"
          smooth={true}
          to="contacts"
          offset={-110}
          className="btn-main-offer"
        >
          {texts.header.button[language]}
        </Link>
      </div>
    </div>
  );
};

export default Header;
